import { load } from '@fingerprintjs/botd'
import FingerprintJSFree from '@fingerprintjs/fingerprintjs'
import * as FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

const pro_bot_monitoring_markets = []; // empty array => skip PRO check bot
const free_bot_monitoring_markets = []; // empty array => FREE check bot on all non-PRO markets
const urlParams = new URLSearchParams(window.location.search);
const market = urlParams.get('hl');
const domain = window.location.hostname;

const domain_whitelist = [
    'seekersensei.com'
];

function returnToOriginalUrl(originalUrl){
    let fetchUrl = originalUrl+'&nlp=1';
    fetchUrl = fetchUrl.replace("%AND%","&");
    // log('fetching: ' + fetchUrl, "bot-dl_"+today)

    fetch(fetchUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })
    .then(response => response.text())
    .then(html => {
        console.log(html);
        document.open();
        document.write(html);
        document.close();
    })
    .catch(error => console.error('Error:', error));
}

function log(data, file) {
    fetch('/api/log.php?file='+file+'&data='+data, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.text())
    .then(data => {
      console.log('Success:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
} 


var check_enable = false;
var isBot = false;
var fingerprint = {};
var click_id = 1;

if(!domain_whitelist.includes(domain)){
    console.log('CHECK');
    if(!click_id){
        click_id = urlParams.get("tclid");
        if(!click_id)
            click_id = urlParams.get("oclid");
    }

    for(var m of pro_bot_monitoring_markets){
        if(market.includes(m)){
            check_enable = true;
            // Initialize an agent at application startup.
            await (async () => {
                // Get the visitor identifier when you need it.
                const fpPromise = FingerprintJS.load({
                    apiKey: "6f8NbjEPPlzBHIa6kJr2"
                });

                const fp = await fpPromise;
                fingerprint = await fp.get({ 
                    extendedResult: true,
                });

                isBot = fingerprint.bot != undefined;
            })();


            break;
        }
    }

    if(!check_enable){
        if(free_bot_monitoring_markets.length == 0){
            check_enable = true;

            // Initialize an agent at application startup.
            const fpPromise = FingerprintJSFree.load();

            await (async () => {
                // Get the visitor identifier when you need it.
                const fp = await fpPromise;
                fingerprint = await fp.get();
        
            })();

            
            const botdPromise = await load();
            isBot = await botdPromise.detect().bot;

            
        }
        else{
            for(var m of free_bot_monitoring_markets){
                if(market.includes(m)){
                    check_enable = true;

                    // Initialize an agent at application startup.
                    // const fpPromise = FingerprintJSFree.load();

                    // await (async () => {
                    //     // Get the visitor identifier when you need it.
                    //     const fp = await fpPromise;
                    //     fingerprint = await fp.get();
                
                    // })();

                    const botdPromise = await load();
                    isBot = await botdPromise.detect().bot;
                    break;
                }
            }
        }
    }


    var _navigator = {};
    try{
        for (var i in navigator) _navigator[i] = navigator[i];
    }
    catch(e){}


    // page_view event
    $.ajax({
        url : "/api/database/BotActivities/index.php?action=create",
        method : 'POST',
        data : JSON.stringify({
            // fingerprint: fingerprint,
            // client: _navigator,
            click_id: click_id,
            event: 'page_view',
            bot: isBot
        }),
        success: function (response) {
            let url = new URL(window.location);
            url.searchParams.delete('trcbt');
            url.searchParams.delete('trcfb');
            url.searchParams.delete('trctt');
            history.replaceState(null, "", url.toString());
        }
    });


    
        
    var clickCount = 0;
    var maxClicks  = isBot ? 1 : 5;

    const nlp = new URLSearchParams(window.location.search).get("nlp") || false;
    let originalUrl = new URLSearchParams(window.location.search).get("original_url");

    const todayDate = new Date();
    const yyyy = todayDate.getFullYear();
    const mm = String(todayDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const dd = String(todayDate.getDate()).padStart(2, '0');

    const today = `${yyyy}-${mm}-${dd}`;


    if(nlp == false && originalUrl != null && originalUrl.includes('test')){
        originalUrl = originalUrl.replace("%AND%","&");
        console.log('Checking if bot');
        if(isBot){
            console.log('You are a bot');
            log("Bot " + window.navigator.userAgent, "bot-dl_"+today);
            log("retrieving learning keywords", "bot-dl_"+today);
            fetch('/api/intrakeywords/getLearningKeywords.php', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
            .then(response => {
                console.log('Response: ', response);

                if(response.status == '404'){
                    console.error("Warning: Learning keyword not found!")
                    log("Learning keyword not found!", "bot-dl_"+today);
                    returnToOriginalUrl(originalUrl);
                }

                if (!response.ok) {
                    return response.text().then(errorText => {
                        throw new Error('Network response was not ok: ' + errorText);
                    });
                }
                
                return response.json();
            })
            .then(data => {
                const keyword = data.keyword;
                log("retrieved learning keyword: " + keyword, "bot-dl_"+today);
                let fetchUrl = originalUrl+'&q='+keyword+'&nlp=1'; //&dlf=1
                fetchUrl = fetchUrl.replace("%AND%","&");
                log("fetching: " + fetchUrl, "bot-dl_"+today);
                history.replaceState(null, "", fetchUrl);
                fetch(fetchUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                })
                .then(response => response.text())
                .then(html => {
                    console.log(html);
                    document.open();
                    document.write(html);
                    document.close();
                })
                .catch(error => console.error('Error:', error));
            })
            .catch(error => {
                console.log(error);
            });
        }else{
            console.log('You are not a bot');
            history.replaceState(null, "", originalUrl.replace("%AND%","&"));
            returnToOriginalUrl(originalUrl);
        }        
    }

    $(document).on('click', '#boxTop a, #boxTop2 a, #boxMiddle a, #boxBottom a', function(event){
        clickCount++;
        var evt = 'clickout_on';
        // if a bot click more than once, block the click event
        if(clickCount > maxClicks){
            console.log("Disable link. Max clicks reached ("+clickCount+"/"+maxClicks+")");
            event.preventDefault();
            evt = 'clickout_off';
        }
        
        //if(isBot){ //enable log for all user/bot
            // clickout event
            $.ajax({
                url : "/api/database/BotActivities/index.php?action=create", 
                method : 'POST',
                data : JSON.stringify({
                    // fingerprint: fingerprint,
                    // client: _navigator,
                    click_id: click_id,
                    event: evt,
                    bot: isBot       
                }),
                success: function (response) {
                    let url = new URL(window.location);
                    url.searchParams.delete('trcbt');
                    url.searchParams.delete('trcfb');
                    url.searchParams.delete('trctt');
                    history.replaceState(null, "", url.toString());
                }
            });
        //}

    });
}